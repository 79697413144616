import React, { useState, useRef, useEffect } from 'react';
import './Sidebar.css';

const Sidebar = ({ sections }) => {
  const [isOpen, setIsOpen] = useState(true); // Default to closed
  const sidebarRef = useRef(null);
  const [selectedId, setSelectedId] = useState('mintPacks'); // Added back the selectedId state
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (sidebarRef.current) {
      const height = isOpen ? sidebarRef.current.scrollHeight : 'auto';
      sidebarRef.current.style.height = height;
    }
  }, [isOpen]);

  const scrollToSection = id => {
    setSelectedId(id); // Update the selectedId state
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };
  

  return (
    <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`} ref={sidebarRef}>
      <button id="mainMenuTitle" onClick={toggleMenu}>
        Access Menu \\
      </button>
      <div className={`content-container ${isOpen ? 'fade-in' : 'fade-out'}`}>
        {isOpen && (
          <>
            <ul className="menu-items">
              {sections.map(section => (
                <li className={`${section.id} ${selectedId === section.id ? 'selected' : ''}`} key={section.id}>
                  {section.url ? (
                    <a href={section.url} target="_blank" rel="noopener noreferrer">
                      <div className="selected-hash">{selectedId === section.id && '// '}</div>{section.title}
                    </a>
                  ) : (
                    <button onClick={() => scrollToSection(section.id)}>
                      <div className="selected-hash">{selectedId === section.id && '// '}</div>{section.title}
                    </button>
                  )}
                </li>
              ))}
            </ul>
            <div className="social-icons">
              <a href="https://twitter.com/Void_2122"><img src="/images/XLogowhite.png" alt="X / Twitter"/></a>
              <a href="https://www.youtube.com/@Void_2122"><img src="/images/yt_icon.png" alt="Youtube"/></a>
              <a href="https://discord.gg/void2122"><img src="/images/discord_logo.png" alt="Discord"/></a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
