import React from 'react';

const Starting3 = () => {
  return (
    <div>
      <h1>Void 2122 Starting Factions \\</h1>
      <p>The year is 2122 and the people of Earth have become space explorers. In the 2040’s
significant scientific advancements allowed for the explorations of space beyond our solar
system. Mega Corporations, Organizations and Allainces were the only way for resources to be
pooled and managed to create all the required essentials for exploring space.</p>
    </div>
  );
};

export default Starting3;
