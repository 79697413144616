import React from "react";
import "./Section2.css"; // Assuming you'll separate out the CSS

const Section2 = () => {
  return (
    <div className="section2Frame">
      <div className="videoTitle">
        <h1>Game Play Demo \\</h1>
        <div className="section2Content">
          <div className="videoFrame">
            <video  controls className="background-video" autoPlay loop muted playsInline>
              <source src="/match_clip.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
