import React, { useRef, useEffect } from 'react';
import "./Section1.css";

const Section1 = () => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const resizeFont = () => {
      const { width } = buttonRef.current.getBoundingClientRect();
      const fontSize = width / 13; // Adjust divisor as needed
      buttonRef.current.style.fontSize = `${fontSize}px`;
    };

    resizeFont();
    window.addEventListener('resize', resizeFont);

    return () => {
      window.removeEventListener('resize', resizeFont);
    };
  }, []);

  const text = "Launch Game"; // Replace with the text you want on the button
  const redirectToURL = () => {
    window.location.href = "https://void2122.com/v2122/Game/"; // Replace with your desired URL
  };

  return (
    <div className="sectionFrame">
      <div className="logoSpacer">
      <div className="logoBox">
        {/* Block containing the logo and tagline */}
        <div className="logo">
          <img src="/images/logo.png" alt="logo" className="logo" />
            <div className="button-container">
              <button className="custom-button" ref={buttonRef} onClick={redirectToURL}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 305 75"
                  className="button-svg">
                  <path fillRule="evenodd"  stroke="rgb(255, 255, 255)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="bevel" fill="rgb(51, 51, 51, 0.6)"
                   d="M2.000,72.000 L2.000,23.213 L23.213,2.000 L302.000,2.000 L302.000,72.000 L2.000,72.000 Z"/>
                </svg>
                <span className="button-text">{text}</span>
              </button>
              <div className='taglineFrame'>
                <div className='tagline'><h3>Sci-Fi Strategy Trading Card Game</h3><h4>// Genesis Unit NFT mint October 31st 2023!</h4> </div>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Section1;
