import React, { useEffect, useState } from 'react';
import './bootstrap/Carousel.scss';
import './App.css';
import './FontSize.scss';
import Section1 from './Components/section1/Section1';
import Section2 from './Components/section2/Section2';
import Section3 from './Components/section3/Section3';
import Section4 from './Components/section4/Section4';
import Section5 from './Components/section5/Section5';
import Sidebar from './Components/Sidebar';  // Import the Sidebar component
import Footer from './Components/Footer/Footer';  // Import the Footer component
import MintPacks from './Components/MintPacks';
import AccountInfoProvider from "./Context/AccountInfo";



const sections = [
  { id: 'mintPacks', title: 'Mint Page', type: 'component', component: <MintPacks /> },
  { id: 'section1', title: 'Void 2122', type: 'component', component: <Section1 /> },
  { id: 'section2', title: 'Game Demo', type: 'component', component: <Section2 /> },
  { id: 'section3', title: 'Lite Paper', type: 'component', component: <Section3 /> },
  { id: 'section4', title: 'Lore', type: 'component', component: <Section4 /> },
  { id: 'section5', title: 'Tutorials', type: 'component', component: <Section5 /> },
  { id: 'launchgame', title: 'Launch Game', url: 'https://void2122.com/v2122/Game/' },
];

const App = () => {
  const [htmlContent, setHtmlContent] = useState({});

  useEffect(() => {
    sections.forEach(section => {
      if (section.type === 'static') {
        fetch(section.contentFile)
          .then(response => response.text())
          .then(text => setHtmlContent(prev => ({ ...prev, [section.id]: text })));
      }
    });
  }, []);

  const renderSectionContent = (section) => {
    if (section.type === 'static') {
      return <div className="section-content" dangerouslySetInnerHTML={{ __html: htmlContent[section.id] }} />;
    }
    if (section.type === 'component') {
      return section.component;
    }
    if (section.action) {
      return <button onClick={section.action}>{section.title}</button>;
    }
    return null;
  };

  return (
    <AccountInfoProvider>
    <div className="App">
      <div className="main-section">
        {sections.map((section) => (
          <div className="section-block" id={section.id} key={section.id}>
            <div className="section-bg" id={section.id}>
              {renderSectionContent(section)}
            </div>
          </div>
        ))}
      </div>
      <Sidebar sections={sections} />
      <Footer /> {/* Add Footer here */}
    </div>
    </AccountInfoProvider>
  );
};

export default App;
