import React, { useState, useContext, useEffect } from "react";
import { Carousel } from 'react-bootstrap';
import Connect from "./Connect.js";
import { AccountInfoContext } from "../Context/AccountInfo.js";
import PackList from "../signatureList/PackListSignature.json";
import "../App-alt.css";
import "./MintPacks.css";

function MintPacks() {
  let accountInfo = useContext(AccountInfoContext);

  const [alert, setAlert] = useState({
    active: false,
    content: null,
    variant: null,
  });

  function displayAlert(message, variant) {
    setAlert({ active: true, content: message, variant: variant });
    setTimeout(function () {
      setAlert({ active: false, content: null, variant: null });
    }, 10000);
  }

  async function findSignedMessage(iterator) {
    let data = null;
    for (let i = 0; i < PackList.length; i++) {
      let key = Object.keys(PackList[i])[0];
      if (parseInt(key) === parseInt(iterator)) {
        data = PackList[i][key];
      }
    }
    console.log(data);
    return data;
  }

  
  const [remainingPacks, setRemainingPacks] = useState(null); // New state variable

  // New function to get remaining packs
  async function fetchPacksRemaining() {
    try {
      let iterator = await accountInfo.UnitMintInstance.methods
        .iterator()
        .call();
      let remaining = 2123 - iterator; // Replace 2123 with your total number of packs
      setRemainingPacks(remaining);  // Update state
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect to call fetchPacksRemaining when component mounts
  useEffect(() => {
    fetchPacksRemaining();
  }, []);

  function renderAlert() {
    if (alert.active) {
      let className = "alert";
      if (alert.variant === "success") {
        className += " success";
      } else if (alert.variant === "warning") {
        className += " warning";
      } else if (alert.variant === "error") {
        className += " error";
      }
      return (
        <div className="alert-box">
          <br />
          <div className={className}>{alert.content}</div>
        </div>
      );
    }
  }
  
  //Function not really used.  Saved for future use
  function renderAllUnits() {
    if (accountInfo.nfts && accountInfo.nfts.length > 0) {
      return accountInfo.nfts.map((nft, index) => (
        <div className="nftImage" key={index}>
          <img src={nft.media[0].raw} height="250px" alt={`NFT ${index}`} />
          <br />
          {nft.title}
        </div>
      ));
    } else {
      return <p>No NFTs found.</p>; // You can provide a message when there are no NFTs.
    }
  }

  //Used to render newly minted units
  function renderMintedUnits() {  
    if (accountInfo.mintedNfts && accountInfo.mintedNfts.length > 0) {
      return (
        <Carousel>
          {accountInfo.mintedNfts.map((nft, index) => {
            return (
              <Carousel.Item key={index}>
                <img
                  className="d-block mint-carousel"
                  src={nft.media[0].raw}
                  alt={`Minted NFT ${index}`}
                />
                <Carousel.Caption>
                  <h3>{nft.title}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      );
    } else {
      return (
        <div className="renderDefaultImage">
          <img src="/images/void2122_genesis_pack.png" alt="Genesis Pack Image" />
        </div>
      );
    }
  }
  

  async function handleMint() {
    try {
      let iterator = await accountInfo.UnitMintInstance.methods
        .iterator()
        .call();
      console.log(iterator);
      let price = await accountInfo.UnitMintInstance.methods.price().call();
      const data = await findSignedMessage(iterator);
      if (data) {
        const gasEstimate = await accountInfo.UnitMintInstance.methods
          .mint(
            accountInfo.account,
            data.pack,
            data.signature.v,
            data.signature.r,
            data.signature.s
          )
          .estimateGas({ from: accountInfo.account, value: price });
  
        const receipt = await accountInfo.UnitMintInstance.methods
          .mint(
            accountInfo.account,
            data.pack,
            data.signature.v,
            data.signature.r,
            data.signature.s
          )
          .send({ from: accountInfo.account, value: price, gas: gasEstimate });
  
        const transactionHash = receipt.transactionHash;
        accountInfo.getMintedNfts(transactionHash);
      } else {
        displayAlert("Failed to fetch pack data.");
      }
    } catch (error) {
      let simplifiedMessage = "An error occurred";
  
      if (error.message.includes("Mint Closed")) {
        simplifiedMessage = "Mint is Closed";
      } else if (error.message.includes("insufficient gas")) {
        simplifiedMessage = "Insufficient gas";
      } else if (error.message.includes("nonce too low")) {
        simplifiedMessage = "Nonce too low";
      } else if (error.message.includes("insufficient funds")) {
        simplifiedMessage = "Insufficient funds";
      } 
  
      displayAlert(simplifiedMessage);
    }
  }

  const [countdown, setCountdown] = useState('');

  // Function to calculate the time remaining until 00:00 UTC
  const calculateTimeRemaining = () => {
    const now = new Date();
    const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0));
    const diff = utcDate - now + 86400000; // Add 86400000 milliseconds (1 day) to get next 00:00 UTC
    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  // useEffect to update the countdown every second
  useEffect(() => {
    setCountdown(calculateTimeRemaining());
    const timer = setInterval(() => {
      setCountdown(calculateTimeRemaining());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [mintingLive, setMintingLive] = useState(false); // Add a new state variable to track if minting is live

  // useEffect to update the countdown every second
  useEffect(() => {
    const updateCountdown = () => {
      const timeRemaining = calculateTimeRemaining();
      if (timeRemaining === '00:00:00') {
        setMintingLive(true);  // Set minting to live when it's 00:00:00
      } else {
        setCountdown(timeRemaining);
      }
    };

    updateCountdown();
    const timer = setInterval(updateCountdown, 1000);
    
    return () => clearInterval(timer);
  }, []);


  return (
    <div class="mintContainer">
      <div class="backgroundContainer">
        <img src="/images/scifi_hex.jpg" alt="Background" class="backgroundImage" />
      </div>
      <div class="contentContainer">
        <div class="textContainer">
        <h2>Mint a Genesis Pack of 6 Void 2122 Units:</h2>
        <ul>
            <li>There will be 2122 packs available to mint, each containing 6 rare cards.</li>
            <li>Discover from a pool of 55 unique cards in this Genesis collection.</li>
            <li>Minting a pack costs 0.1 ETH.</li>
            <li>100% of the Genesis mint funds will be allocated to game development.</li>
            <li>These funds will expedite ongoing development for significant upgrades.</li>
            <li>The Genesis mint funds will facilitate the hiring of two additional coders and one artist.</li>
            <li>They will also strengthen marketing efforts for broader outreach.</li>
            <li>A portion will be dedicated to the inaugural bracketed tournament prize pool.</li>
            <li>For collectors who obtain all 55 possible cards in the Genesis launch, there are spectacular rewards:
                <ul>
                    <li>First Collector Prize: 1.0 ETH + Deed to a Random Planet</li>
                    <li>Second Collector Prize: 0.5 ETH + Deed to a Random Planet</li>
                    <li>Third Collector Prize: 0.25 ETH + Deed to a Random Planet</li>
                    <li>All Other Collectors: Deed to a Random Planet</li>
                </ul>
            </li>
        </ul>
        <p>If you missed the Genesis Mint, don't worry! Normal booster packs are set to launch on December 1st, 2023, at an approximate price of $12 USD. Please note that these regular booster packs will follow standard drop rates for common, uncommon, and rare cards.</p>

        </div>
        <div class="mintingContainer">
        <div class="renderMintedUnits">{renderMintedUnits()}</div>
          <div class="mintButtonArea">
            {/*<div className="packsRemaining">Packs Remaining: {remainingPacks}</div>*/}
            <div className="countdown">Minting is Live!<br /><br /></div>
            
            <button class="mintButton" onClick={() => handleMint()}>Mint 1 Genesis Pack 0.1 Eth</button>
          </div>
        </div>        
        <div class="alertContainer">
          <div className="connectWrapper"><Connect /></div>
          <div className="mintAlert">{renderAlert()}</div>
        </div>
      </div>
    </div>
  );
}

export default MintPacks;