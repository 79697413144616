import React from 'react';

const AboutUsContent = () => {
  return (
    <div>
      <h1>Free Trade Guild \\</h1>
      <p>The Free Traders' Guild emerged in defiance of the galaxy's major factions' oppressive trade policies. Independent traders and smugglers, tired of being marginalized or legally targeted for competing with the dominant powers, united to create a loose network. This alliance allowed them to pool resources, exchange information, and shield each other from retaliation by major factions.</p>
      <p>Over time, the Guild expanded, drawing like-minded individuals who championed free trade and personal freedom. Their operations thrived in the galaxy's fringes, where major factions held less sway, and demand for restricted or illicit goods was high. Guild members, skilled in smuggling, hacking, and negotiation, maintained their edge through resourcefulness.</p>
      <p>Operating from the secretive asteroid base, "The Den," the Guild served as a secure meeting point, intelligence hub, and asset storage for its members. Its leaders remained enigmatic, communicating exclusively through secure channels, preserving anonymity with code names and encrypted messages.</p>
    </div>
  );
};

export default AboutUsContent;
