import React from 'react';

const Starting3 = () => {
  return (
    <div>
      <h1>Synocore \\</h1>
      <p>Founded as a modest Earth communications startup in the early 21st century, Synocore swiftly gained renown for its reliable and affordable internet and phone services. Growing beyond terrestrial boundaries, the company expanded into satellite communications and cybersecurity. By the 2040s, Synocore had become Earth's telecommunications giant, serving governments, corporations, and individuals worldwide.</p>
      <p>Yet, Synocore's ambitions extended to the cosmos. With the rise of space exploration, it invested heavily in space-based telecommunications infrastructure. Over time, it secured dominance over the "West Sector" of the galaxy, connecting star systems and colonies with its services. </p>
      <p>As the conglomerate expanded, allegations of ruthless business practices emerged, from price-fixing to corporate espionage. Synocore's defenders insisted it was all about delivering exceptional technology and services.
</p>
      <p>Its rivalry with Wabiru Corporation remained a complex dance, governed by treaties but shadowed by tension. Some believed a covert war simmered beneath the surface. Synocore's journey from humble origins to intergalactic dominance is a testament to its unwavering pursuit of excellence and power.</p>
      
    </div>
  );
};

export default Starting3;
