import React, { useState, useRef, useEffect } from 'react';
import './Section5.css';

const Section5 = () => {
  const [selectedVideo, setSelectedVideo] = useState("/videos/connecting.mp4");
  const [selectedTitle, setSelectedTitle] = useState("Connecting to Neural Network");

  const videoRef = useRef(null);

  const videos = [
    { title: 'Connecting to Neural Network', file: '/videos/connecting.mp4' },
    { title: 'This is your Shake N Draw', file: '/videos/shake-n-draw.mp4' },
    { title: 'Guide to Interstellar Matches', file: '/videos/matches.mp4' },
  ];

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [selectedVideo]);

  const handleVideoChange = (file, title) => {
    setSelectedVideo(file);
    setSelectedTitle(title);
  };

  return (
    <div className="section5-frame">

      <div className="videoFrame">
        <div className="video-title">{selectedTitle}</div>
        <video ref={videoRef} controls className="video-player" autoPlay loop muted playsInline>
          <source src={selectedVideo} type="video/mp4" />
        </video>
      </div>
      <div className="video-menu">
        <ul>
          {videos.map((video, index) => (
            <li key={index} onClick={() => handleVideoChange(video.file, video.title)}>
              {video.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Section5;
