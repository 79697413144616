import React, { Component } from "react";
import Web3 from "web3";
import { Button } from "react-bootstrap";
import { AccountInfoContext } from "../Context/AccountInfo";
// import CorporationContract from "../contracts/Corporation.sol/Void2122Corporation.json";
// import FactoryContract from "../contracts/Factory.sol/Void2122Factory.json";
// import LootContract from "../contracts/Loot.sol/Void2122Loot.json";
// import ModContract from "../contracts/Mod.sol/Void2122Mod.json";
// import SchematicContract from "../contracts/Schematic.sol/Void2122Schematic.json";
import UnitContract from "../contracts/Unit.sol/Void2122Unit.json";
import UnitMintContract from "../contracts/UnitMint.sol/UnitMint.json";
import contractsData from "../contracts/contractsData.json";
import { Alchemy, Network } from "alchemy-sdk";

class Connect extends Component {
  static contextType = AccountInfoContext;

  componentDidMount = async () => {
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      this.web3 = new Web3(window.web3.currentProvider);
    } else {
      var provider = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PROJECT_ID1}`; 
      var web3Provider = new Web3.providers.HttpProvider(provider);
      this.web3 = new Web3(web3Provider);
    }
    this.context.updateAccountInfo({ web3: this.web3 });
    if (this.web3) {
      await this.setNetwork();
      await this.getContractsInstances();
      if (window.ethereum || window.web3) {
        await this.setAccount();
      }
    }
    this.context.updateAccountInfo({ getMintedNfts: this.getMintedNfts.bind(this) });  //added this to update mintedNfts
  };

  async getContractsInstances() {
    this.networkId = await this.web3.eth.getChainId();
    let networkName;
    if (this.networkId === 5) {
      networkName = "goerli";
    } else if (this.networkId === 1) {
      networkName = "mainnet";
    }
    this.context.updateAccountInfo({
      networkName: this.networkName,
      networkId: this.networkId,
    });

    console.log(contractsData);

    this.UnitInstance = new this.web3.eth.Contract(
      UnitContract.abi,
      contractsData[networkName]["Unit"].proxy
    );

    this.UnitMintInstance = new this.web3.eth.Contract(
      UnitMintContract.abi,
      contractsData[networkName]["UnitMint"].contract
    );

    this.context.updateAccountInfo({
      UnitInstance: this.UnitInstance,
      UnitMintInstance: this.UnitMintInstance,
    });
    this.getUnitContractInfo();
    this.getUnitMintContractInfo();
    this.context.updateAccountInfo({ instancesLoaded: true });
  }

  async setAccount() {
    if (this.context.networkId !== null) {
      let accounts = await this.web3.eth.getAccounts();
      await this.context.updateAccountInfo({ account: accounts[0] });
      if (this.context.account) this.getAccountsData(accounts[0]);
    } else {
      this.resetAccountData();
    }
  }

  resetAccountData() {
    this.context.updateAccountInfo({
      account: null,
    });
  }

  async setNetwork() {
    let networkId = await this.web3.eth.getChainId();
    this.context.updateAccountInfo({
      networkId: networkId,
    });
  }

  async getAccountsData() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      this.context.updateAccountInfo({
        walletETHBalance: await this.web3.eth.getBalance(this.context.account),
      });
       this.getVOID2122NFTs();
    }
  }


  // Fetch all NFTs held by the connected wallet address
  async getVOID2122NFTs() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      const config = {
        apiKey: process.env.REACT_APP_ALCHEMY_KEY,
        network: Network.ETH_MAINNET,
      };
      const alchemy = new Alchemy(config);

      console.log("Fetching NFTs for account:", this.context.account);

      try {
        const response = await alchemy.nft.getNftsForOwner(this.context.account, {
          contractAddresses: [contractsData.mainnet["Unit"].proxy],
        });

        console.log("Alchemy API response:", response);
   
        this.context.updateAccountInfo({
          nfts: response.ownedNfts,
          nftsLoaded: true,
        });
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      }
    }
  }

  //Fetches NFTs in connected wallet and filters by transaction hash
  async getMintedNfts(transactionHash) {
    const config = {
      apiKey: process.env.REACT_APP_ALCHEMY_KEY,
      network: Network.ETH_MAINNET,
    };
    const alchemy = new Alchemy(config);
    console.log('Fetching minted NFTs for account:', this.context.account);
    try {
      //console log contractsData.mainnet["Unit"].proxy
      console.log('Contracts Data:', contractsData.mainnet["Unit"].proxy);
      const response = await alchemy.nft.getMintedNfts(this.context.account, {
        contractAddresses: [contractsData.mainnet["Unit"].proxy],
      });
      console.log('Alchemy API response:', response);
      // Filter by transaction hash
      const mintedNfts = response.nfts.filter(nft => nft.transactionHash === transactionHash);
      console.log('Minted NFTs:', mintedNfts);
      this.context.updateAccountInfo({
        mintedNfts: mintedNfts,
        mintedNftsLoaded: true,
      });
    } catch (error) {
      console.error("Error fetching Minted NFTs:", error);
    }
  }
  
  async getUnitContractInfo() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
    }
  }

  async getUnitMintContractInfo() {
    if (
      this.context.networkId === parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      let iterator = await this.context.UnitMintInstance.methods
        .iterator()
        .call();
    }
  }

  async connectWallet() {
    this.context.updateAccountInfo({ transactionInProgress: true });
    try {
      window.ethereum.enable();
    } catch (error) {
      console.log(error);
    }
    this.context.updateAccountInfo({ transactionInProgress: false });
  }

  getAccountStr(account) {
    let response =
      account.slice(0, 5) + "..." + account.substring(account.length - 2);
    return response;
  }

  renderUserInterface() {
    if (!this.context.account) {
      return (
        <Button
          variant="outline-light"
          className="connectButton"
          onClick={() => this.connectWallet()}
        >
          Connect Wallet
        </Button>
      );
    } else if (
      parseInt(this.context.networkId) !==
      parseInt(process.env.REACT_APP_MAINNET_NETWORK)
    ) {
      return (
        <p style={{ color: "white" }}>
          Please connect to{" "}
          {parseInt(process.env.REACT_APP_MAINNET_NETWORK) === 1
            ? "Ethereum Network"
            : "the GOERLI Network"}
        </p>
      );
    } else
      return (
        <Button
          variant="outline-light"
          id="interface_connection"
          className="connectButton"
        >
          Connected as {this.getAccountStr(this.context.account)}
        </Button>
      );
  }

  render() {
    if (window.ethereum || window.web3) {
      if (this.web3) {
        window.ethereum.on("accountsChanged", async () => {
          await this.setAccount();
        });
        window.ethereum.on("chainChanged", async () => {
          await this.setNetwork();
          await this.setAccount();
        });
      }
    }
    return this.renderUserInterface();
  }
}

export default Connect;
