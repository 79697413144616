import React from 'react';
import Starting3 from './Starting3';
import Synocore from './Synocore';
import FTG from './FTG';
import IA from './IA';
import './Section4.css';

const Section4 = () => {
  const contents = [
    { id: 'starting3', content: <Starting3 />, background: '/images/lorebg1.jpg' },
    { id: 'ia', content: <IA />, background: '/images/ia1.jpg' },
    { id: 'ftg', content: <FTG />, background: '/images/ftg1.jpg' },
    { id: 'synocore', content: <Synocore />, background: '/images/synocore1.jpg' },
  ];

  return (
    <div className="section4-container">
      {contents.map((item, index) => (
        <div key={index} className="section4Frame" id={item.id}>
          <div className="background-container">
            <img src={item.background} alt="Background" />
          </div>
          <div className="section4-coverimg"></div>
          <div className="section4-contentFrame">
            <div className="section4-content">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Section4;
