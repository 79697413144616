import React from "react";
import "./Section3.css";

const Section3 = () => {
  return (
    <div className="section3Frame" id="LitePaper">
      <div className="section3BG"></div>
      <div className="background-container">
        
      </div>
      <div className="section3-coverimg"></div>
      <div className="section3-contentFrame">
        <div className="section3-content">
        <h1 id="litePaper">Void 2122 Lite Paper \\</h1>
          <p>Void 2122 was born from both frustration and vision. In February 2022, we, the founders, often
              discussed the state of Web 3 gaming. We found that many ambitious projects seemed infeasible
              or would take years to develop. Existing games lacked entertainment value and felt like
              speculative ventures fueled by NFT gaming hype. Our solution? A card game, tailored for the
              NFT trend and easier to develop than an expansive 3D world.</p>
          
          <p>Void 2122 operates on what we call the "Three E’s": Entertaining, Engaging, Evolving. Every
              design choice had to align with these principles. In addition, we had to work within
              constraints—rules, resources, and time.</p>
          
          <p>For a game to succeed, it must be fun. Too simple, and players would quickly lose interest; too
              complex, and the audience narrows. Our aim was to create a challenging yet accessible game
              where players can pit their wits against AI or human opponents, much like Pokémon's quest to
              find and train stronger pokemons or in our case units.</p>
          
          <p>We drew on game theory from Magic: The Gathering and Pokémon, both notably successful
              franchises, and sought to improve upon them. Imagine being able to create your own card and
              use it in a game. This community engagement was core to our vision.</p>
          
          <p>Moreover, letting players create their own game pieces naturally generates new content. World
              of Warcraft successfully integrated player-generated content, increasing player investment. With
              Void 2122, we wanted to achieve something similar, but with fewer coding constraints. It's a
              delicate balance: too much freedom risks taking the game in undesirable directions, while too
              little stifles creativity.</p>
          
          <p>Void 2122 is a game by gamers, for gamers, from start to finish. We set rules that are fair but
              flexible, pushing boundaries where possible. Financial constraints led us to focus on developing
              the first system in the Void 2122 universe, with plans to carefully and gradually expand. Our
              mission: to create a dynamic, evolving game that captivates and engages its community.</p>
          
          <h1>What is Void 2122:</h1>
          
          <p>Set in a cyberpunk sci-fi universe, Void 2122 is a free-to-play strategy card game. The objective
              is straightforward: control the majority of slots in a 5x5 grid to win. But the real excitement lies in
              how you achieve this. We've balanced the game to accommodate both serious and casual
              gamers through a triad of elements: Time, Money, and Strategy.</p>
          
          <p>Hardcore players investing significant "Time" will have powerful, upgraded units due to
              experience gains from matches. Casual players can leverage "Money" to buy strong units for
              quick wins. "Strategy" is the universal factor; even a weak deck can defeat a powerful one with
              the right approach. We confirmed this by testing against an AI with optimal move calculations
              and a weaker deck—and we often lost.</p>
          
          <p>So, choose any two from Time, Money, and Strategy to be competitive. When two players are
              evenly matched across these elements, luck becomes the deciding factor, as it should be.</p>
          
          <h1>Void 2122 Storyline:</h1>
          <p>The year is 2122 and the people of Earth have become space explorers. In the 2040’s
              significant scientific advancements allowed for the explorations of space beyond our solar
              system. Mega Corporations, Organizations and Allainces were the only way for resources to be
              pooled and managed to create all the required essentials for exploring space. 3 of the earliest
              were the following:</p>
          
          <p>The Free Trade Guild: The Free Trade Guild are the descendants of the earliest explorers and
              colonists from Earth. They are so removed from any connection to Earth that they are only
              aware of its general location and existence from trade. The FTG separated from Earth many
              generations ago.</p>
          <p>The FTG have no desire to return to Earth. They have built a new life for themselves on their
              adopted planet, and they are content to live in peace and isolation. However, they are aware of
              the growing tensions between the Inner Alliance and the Synocore, and they fear that they may
              be drawn into the conflict.</p>
          <p>The Inner Alliance: The Inner Alliance is a coalition of old world governments formed on Earth
              in the early days of space exploration. As more outpost and colonies were established The
              Inner Alliance shifted from funding the exploration of space, to keep a working balance for trade
              and commerce. However challenges began to form as explorers began to venture further and
              further away.</p>
          <p>Synocore: A collection of megacorporations that all originated from Earth. In the early days of
              space exploration, these corporations were eager to expand their reach and profits. However,
              they soon found themselves hampered by the regulations and taxes imposed by Earth's
              government.</p>
          <h1>Our Objective:</h1>
          <p>The Web 3 gaming space is filled with hype and vast potential. Our vision is to serve as a bridge
              connecting traditional gamers with the burgeoning Web 3 gaming ecosystem. While we aspire to
              create an immersive Void2122 metaverse, we recognize that's a long-term goal. Our approach
              is measured, building one carefully-considered component at a time to maintain balance
              between the hype, the product, and the vision. The strategy card game is merely our first
              building block. The future holds endless possibilities, like a 3D universe where players pilot their
              units in real-time battles for territorial control. But reaching that level of complexity will take
              incremental, deliberate steps—perhaps thousands or even millions of them.</p>
          <h1>What Sets Our Game Apart:</h1>
          <p>Unlike existing Web 3 games, our mechanics diverge from the conventional Magic the
              Gathering/Pokemon styles. We offer a playable game today, not just a 30-second preview. Our
              commitment to transparency extends to releasing quarterly financial reports, showcasing how
              revenue from Void 2122 unit sales gets reinvested in the game. Despite blockchain's promise of
              transparency, many projects fall short; we aim to be different.</p>
          <h1>Revenue Model for Void 2122:</h1>
          <p>Revenue flows from the sale of Units/NFTs, which fuels further development of the Void 2122
              universe. A 10% fee applies to secondary transactions, but note that this rate is subject to
              increase with the introduction of "Factories."</p>
          <h1>Play-to-Earn in Void 2122:</h1>
          <p>Your investment in time, creativity, and money in Void 2122 has alternative uses, making it your
              opportunity cost. Our aim is a win-win: the more you engage, the more you gain in loot and
              experience. Even casual NFT collectors benefit, as owned units gain experience and value over
              time.</p>
          <p>Community involvement matters too. You don't have to excel at gameplay to contribute and
              enjoy the Void 2122 universe. For instance, in Everquest, my character wasn't strong, but I
              thrived by trading items, setting the stage for "auctioneers" in later MMORPGs like World of
              Warcraft.</p>
          <p>Our economic model is designed for self-sustainability and growth, with checks and balances to
              limit external influences. We track key metrics to guide development towards our end vision: not
              wealth, but a fun, lasting bridge to Web 3 gaming.</p>
          <h1>Void 2122 Player Engagement:</h1>
          <p>While playing against AI (PvA) offers some enjoyment, it lacks unpredictability. The true test of
              your skills lies in Player vs Player (PvP) matches. A vibrant player base is crucial for this, so
              we're rolling out a range of events and tournaments with cash rewards. Our ultimate goal? A
              six-figure grand prize tournament. More players equal more thrilling matches.</p>
          <h1>How to Play Void 2122:</h1>
          <p>Reading about gameplay isn't as compelling as experiencing it, which is why we launched a
              functioning game before selling any NFTs. We offer video tutorials to get you started—watch
              and then dive right in. Learning by doing is always more effective.</p>
          <h1>Do I need to buy an NFT to Play Void 2122?</h1>
          <p>No, the game is genuinely free to play with a starter deck provided to all players. The starter
              deck is identical for all new players, and starter deck units cannot be traded, upgraded or
              modified. As you engage in live (PvA) and PvP matches, you'll earn loot, which could be new
              units, mods, or parts for unit creation. Credits for in-game purchases will also be available.
              Initially, only will only be able to collect credits and loot; trading and selling features will come
              later. This cautious approach ensures proper loot distribution and maintains a balanced,
              enjoyable game for everyone.</p>
          <h1>Game Mechanics and Blockchain Integration:</h1>
          <p>Void 2122 operates on a hybrid structure, separating the gameplay mechanics from the
              blockchain elements like player owned assets like Unit(s), Loot and Credits. This was a
              pragmatic choice driven by our resource and time constraints. Creating a seamlessly integrated
              game with full blockchain capabilities would have required significant funding or extended
              development time—resources we didn't have at the moment.</p>
          <p>Our current approach allows us to proceed with available means, with plans to incrementally
              embed more blockchain interactions into the gameplay during Phase 2. This step-by-step
              integration is designed to optimize both player experience and blockchain utility.</p>
          <h1>Void 2122 Roadmap:</h1>
          <p><strong>**Phase 1 (Mostly Implemented; Awaiting Real-Time Data for Loot, Ranking, and XP)**</strong></p>
              <ul>- Gen 0 Unit NFT Launch</ul>
              <ul>- Open Beta</ul>
              <ul>- Enable Unit Upgrades</ul>
              <ul>- Activate Loot Minting</ul>
              <ul>- In-Game Credits</ul>
              <ul>- Mod Drops</ul>
              <ul>- Beta to Final Transition (Minimal changes, aim to preserve player data; may require reset in
              extreme cases)</ul>
              <ul>- Tournaments</ul>
              <ul>- In-Game and Out-Game Events</ul>
              <strong>**Phase 2 (In Development)**</strong>
              <ul>- One-time Factory NFT Sale</ul>
              <ul>- Activate Factories</ul>
              <ul>- Out-Game Token Introduction</ul>
              <ul>- Unit Specials Testing</ul>
              <ul>- Battle Animations Testing</ul>
              <ul>- Battlefield Mods Testing</ul>
              <strong>**Phase 3 (Conceptual Stage)**</strong>
              <ul>- To be decided based on Phase 2 outcomes</ul>
          <p>We're in a unique position while drafting whitepapers and roadmaps. We've coded lots of
              surprises, and we enjoy the element of discovery for our players. Striking a balance between
              transparency and discovery is an interesting challenge.</p>
          <div id="s3contentspacer"></div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
