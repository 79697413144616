import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer-frame'>
      <div className="footer-container">
        <div className="footer-column">
          <h4></h4>
          <ul>
            <li><a href="/Updates.html" target="_blank" rel="noreferrer">Updates \\</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4></h4>
          <ul>
            <li><a href="/AboutUs.html" target="_blank" rel="noreferrer">About Us \\</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4></h4>
          <ul>
            <li><a href="/MediaKits.html" target="_blank" rel="noreferrer">Media Kits \\</a></li>
            <li><a href="mailto:team@void2122.com?subject=Partnership%20with%20Void2122">Partnerships \\</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
